@import './node_modules/@sbt-suite/components/theme/variables.scss';
@import './node_modules/@sbt-suite/components/theme/globalClasses.scss';

:root {
    --primary-color: #376982;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;

    * {
        box-sizing: border-box;
    }
}

.statusLabel {
    &.badge-nao-homologado,
    &.badge-homologado {
        padding: 3px 6px;
        color: #fff;
        font-weight: 500;
        font-size: 0.6875rem;
        border-radius: 15px;
        text-transform: capitalize;
    }
    &.badge-nao-homologado {
        background-color: #b69205;
    }
    &.badge-homologado {
        background-color: #2ad094;
    }
}

.page-break, .page-print{
     display: none;   
}

@media print {
    .page-print{
        display: block;
    }
    body, .mat-drawer-container{
        background-color: #fff!important;
    }
    div {
        border: none;
        box-shadow: none;
    }

    nav-bar, mat-drawer, copyright, cdk-virtual-scroll-viewport{
        display: none!important;
    }

    mat-card {
        box-shadow: none!important;
        margin-bottom: 0!important;
    }

    .mat-drawer-content{
        margin-left: 16px!important;
    }

    .menu-lateral{
        height: 4000px!important;
    }

    p.margem{
        margin-top: 0!important;
    }

    .page-break{
        display: block;
        page-break-before: always;
    }
}
